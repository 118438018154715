export class kvOpening {
  constructor() {
    const kvSub = document.querySelector('.js-kv_sub')
    const kvMain = document.querySelector('.js-kv_main')
    const kvJa = document.querySelector('.js-kv_ja')
    const kvDate = document.querySelector('.js-kv_date')
    if (!kvSub) return
    setTimeout(() => {
      kvSub.classList.add('is-act')
      kvMain.classList.add('is-act')
    }, 300)
    setTimeout(() => {
      if (kvJa) {
        kvJa.classList.add('is-act')
      }
    }, 800)
    setTimeout(() => {
      if (kvDate) {
        kvDate.classList.add('is-act')
      }
    }, 1000)
  }
}
