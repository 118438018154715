export class CopyClipboard {
  constructor() {
    const copyTrgs = document.querySelectorAll('.js-copy_trg')
    copyTrgs.forEach((elm) => {
      elm.addEventListener(
        'click',
        (e) => {
          const target = e.currentTarget.previousElementSibling
          navigator.clipboard.writeText(target.textContent).then(
            (success) => alert(`コピーに成功しました！ ${target.textContent}`),
            (error) => alert(`コピーに失敗しました！`)
          )
        },
        false
      )
    })
  }
}
