export class Tab {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const getIndex = (element) => {
        if (!element) {
          return -1
        }
        let currentElement = element
        let index = 0
        while (currentElement.previousElementSibling) {
          index++
          currentElement = currentElement.previousElementSibling
        }
        return index
      }
      const tabs = document.querySelectorAll('.js-tabs')
      const storageTab = sessionStorage.getItem('tab')

      tabs.forEach((elm) => {
        const trgs = elm.querySelectorAll('.js-tab_trg')

        const body = elm.nextElementSibling
        trgs.forEach((trg, i) => {
          if (storageTab) {
            trg.classList.remove('is-act')
            if (i == storageTab) {
              console.log('storageTab:' + storageTab, 'i:' + i)
              trg.classList.add('is-act')
              for (let i = 0; i < body.children.length; i++) {
                const bodyElm = body.children[i]
                bodyElm.classList.remove('is-show')
              }
              body.children[storageTab].classList.add('is-show')
            }
          }
          trg.addEventListener(
            'click',
            (e) => {
              const index = getIndex(e.currentTarget)
              if (e.currentTarget.classList.contains('is-disable')) return false
              for (let i = 0; i < body.children.length; i++) {
                const bodyElm = body.children[i]
                bodyElm.classList.remove('is-show')
              }
              trgs.forEach((elm) => {
                elm.classList.remove('is-act')
              })
              trg.classList.add('is-act')
              body.children[index].classList.add('is-show')
              sessionStorage.setItem('tab', index)
            },
            false,
          )
        })
      })
    })
  }
}
