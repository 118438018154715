import Swiper from 'swiper'
import 'swiper/css'

export class IndexProgramSlider {
  constructor() {
    this.slider = document.querySelector('.js-program_slider')
    if (!this.slider) return

    window.addEventListener('load', () => {
      this.initSwiper()
    })
  }

  initSwiper() {
    const options = {
      slidesPerView: '1.3',
      grabCursor: true,
      centeredSlides: true,
      spaceBetween: 15,
      breakpoints: {
        768: {
          spaceBetween: 40,
          slidesPerView: '1.4',
        },
      },
    }

    const swiper = new Swiper(this.slider, options)
  }
}
