import { gsap } from 'gsap'
/**
 * メガメニュー
 */
export class MegaMenu {
  constructor() {
    this.megaTrgs = [...document.getElementsByClassName('js-mega_trg')]
    if (this.megaTrgs.length == 0) return
    this.megaTrgs.forEach((megaTrg) => {
      const megaContent = megaTrg.getElementsByClassName('js-mega_content')
      megaTrg.addEventListener('mouseenter', () => {
        megaTrg.classList.add('is-act')
        this.animation(megaContent, 'visible')
      })
      megaTrg.addEventListener('mouseleave', () => {
        megaTrg.classList.remove('is-act')
        this.animation(megaContent, 'hidden')
      })
    })
  }
  animation(el, type) {
    if (type == 'visible') {
      el[0].classList.add('is-visible')
      gsap.to(el, 0.3, { autoAlpha: 1, display: 'block' })
    } else {
      el[0].classList.remove('is-visible')
      gsap.to(el, 0.3, { autoAlpha: 0, display: 'none' })
    }
  }
}
