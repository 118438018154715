import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

export class BrandsSlider {
  constructor() {
    const slide = document.getElementById('js-index_brands')
    if (!slide) return
    const animation = { duration: 30000, easing: (t) => t }
    this.slideArr = [...document.getElementsByClassName('js-brands_slider')]
    this.slideArr.forEach((slider) => {
      if (slider.children.length < 6) {
        slider.classList.remove('keen-slider')
      } else {
        let sliderFunc = new KeenSlider(slider, {
          loop: true,
          renderMode: 'performance',
          // drag: false,
          slides: {
            perView: 3,
            spacing: 5,
          },
          breakpoints: {
            '(min-width: 768px)': {
              slides: {
                perView: 5,
                spacing: 10,
              },
            },
          },
          created(s) {
            s.moveToIdx(5, true, animation)
          },
          updated(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation)
          },
          animationEnded(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation)
          },
        })
      }
    })
  }
}
