import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

export class OtherSessionSlider {
  constructor() {
    const slide = document.getElementById('js-other_session_slider')

    if (!slide) return
    const slider = new KeenSlider('#js-other_session_slider', {
      loop: false,
      mode: 'free-snap',
      slides: {
        perView: 1.5,
        spacing: 10,
      },
      breakpoints: {
        '(min-width: 768px)': {
          slides: {
            perView: 2.4,
            spacing: 30,
          },
        },
      },
    })
  }
}
