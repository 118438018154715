export class SessionCheck {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      // const compareTimes = (startTime, endTime) => {
      //   const [startHour, startMinute] = startTime.split(':').map(Number)
      //   const [endHour, endMinute] = endTime.split(':').map(Number)

      //   if (startHour < endHour || (startHour === endHour && startMinute < endMinute)) {
      //     return true // startTimeがendTimeより早い
      //   }
      //   return false // startTimeがendTimeより遅いまたは同じ
      // }

      // const updateClassBasedOnTime = (nextBlock) => {
      //   const element = document.querySelector('.your-element-selector') // 対象の要素を選択

      //   if (compareTimes(nextBlock.startTime, nextBlock.endTime)) {
      //     element.classList.add('is-disabled')
      //   } else {
      //     element.classList.remove('is-disabled')
      //   }
      // }

      const trgs = document.querySelectorAll('.js-reserve_trg')
      let reservationText = ''
      let cancelText = ''

      const formLang = document.querySelector('.js-form')
      if (!formLang) return
      if (formLang.classList.contains('js-form_en')) {
        reservationText = 'Select'
        cancelText = 'Cancel'
      } else {
        reservationText = '予約する'
        cancelText = 'キャンセル'
      }

      trgs.forEach((data) => {
        data.addEventListener('change', (e) => {
          const target = e.currentTarget

          const label = target.querySelector('.js-reserve_text')
          const targetBlock = target.closest('.js-session_block')
          const targetCard = target.closest('.js-session_card')
          const parentBlock = target.closest('.c-session_block')
          const time = targetCard.querySelector('.js-session_time')
          const endTime = time.textContent.split('~')[1].trim()

          target.classList.toggle('is-cancel')
          targetCard.classList.toggle('is-act')
          if (target.classList.contains('is-cancel')) {
            if (label) {
              label.textContent = cancelText
            }
          } else {
            if (label) {
              label.textContent = reservationText
            }
          }
          const cards = targetBlock.querySelectorAll('.js-session_card')
          cards.forEach((elm) => {
            elm.classList.remove('is-disabled')
            if (!elm.classList.contains('is-act') || elm.classList.contains('is-sold')) {
              elm.classList.add('is-disabled')
            }
          })
          if (label) {
            if (label.textContent === reservationText) {
              cards.forEach((elm) => {
                if (!elm.classList.contains('is-sold')) {
                  elm.classList.remove('is-disabled')
                }
              })
            }
          }

          //次のブロックも確認
          const nextBlock = parentBlock.nextElementSibling
          if (!nextBlock) return
          const nextCards = nextBlock.querySelectorAll('.js-session_card')
          nextCards.forEach((elm) => {
            const time = elm.querySelector('.js-session_time')
            const startTime = time.textContent.split('~')[0].trim()
            if (!elm.classList.contains('is-sold')) {
              elm.classList.remove('is-disabled')
            }
            if (target.classList.contains('is-cancel')) {
              if (!elm.classList.contains('is-act')) {
                if (startTime < endTime) {
                  elm.classList.add('is-disabled')
                }
              }
            }
          })
        })
      })
    })
  }
}
