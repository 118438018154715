import { UaCh } from '../foundation/UserAgent'
export class BrowserCheck {
  constructor() {
    //2024.08.21時点での最新バージョン(Opera,ArcはChrome判定)
    const latestVersions = {
      chrome: 127,
      firefox: 129,
      safari: 17,
      edge: 127,
    }

    const isLatestOrWithinThreeVersions = () => {
      const currentVersion = UaCh.agent.browser.majorVersion
      const latestVersion = latestVersions[UaCh.browser]

      if (!latestVersion) {
        return false
      }
      // 最新バージョンから3バージョン以内かどうかを判定
      if (currentVersion >= latestVersion - 3) {
        return true
      } else {
        return false
      }
    }

    const showModal = () => {
      const modal = document.querySelector('.js-registration_modal')
      const overlay = document.querySelector('.js-registration_modal_overlay')

      if (modal) {
        modal.classList.add('is-show')
        overlay.classList.add('is-show')
      }
    }

    if (UaCh.browser === 'ie') {
      showModal()
    }
    if (!isLatestOrWithinThreeVersions()) {
      showModal()
    }
  }
}
