export class HiddenFixButton {
  constructor() {
    const fixedElm = document.querySelector('.js-fixed_button')
    const footerElm = document.querySelector('.l-footer') // フッター要素を取得
    if (!fixedElm) return

    window.addEventListener('scroll', function () {
      const scroll = window.pageYOffset // スクロール量を取得
      const footerTop = footerElm.getBoundingClientRect().top + window.pageYOffset // フッターの位置を取得

      if (scroll + window.innerHeight < footerTop) {
        fixedElm.style.opacity = 1
        fixedElm.style.visibility = 'visible'
      } else {
        fixedElm.style.opacity = 0
        fixedElm.style.visibility = 'hidden'
      }
    })
  }
}
