import OriginalScrollHint from 'scroll-hint'
import 'scroll-hint/css/scroll-hint.css'

export class ScrollHint {
  constructor() {
    this.scrollContainers = document.querySelectorAll('.js-scrollable')
    this.isDown = false
    this.startX = 0
    this.scrollLeft = 0

    // 画面サイズに応じて動作を切り替える
    if (window.innerWidth > 1023) {
      // 1024px以上の画面ではドラッグ操作を有効にする
      this.scrollContainers.forEach((container) => {
        this.addEventListeners(container)
        container.style.cursor = 'grab' // カーソルをgrabに設定
      })
    }

    // 1023px以下でもScrollHintは常に有効にする
    new OriginalScrollHint('.js-scrollable', {
      suggestiveShadow: true,
      i18n: {
        scrollable: 'スクロールできます',
      },
    })

    // ウィンドウリサイズ時に再チェック
    window.addEventListener('resize', this.handleResize.bind(this))
  }

  handleResize() {
    // リサイズ時に画面サイズを確認し、動作を切り替える
    if (window.innerWidth > 1023) {
      this.scrollContainers.forEach((container) => {
        this.addEventListeners(container)
        container.style.cursor = 'grab'
      })
    } else {
      this.scrollContainers.forEach((container) => {
        this.removeEventListeners(container)
        container.style.cursor = '' // カーソルを初期状態に戻す
      })
    }
  }

  // イベントリスナーの追加
  addEventListeners(container) {
    container.addEventListener('mousedown', (e) => this.onMouseDown(e, container))
    container.addEventListener('mouseleave', () => this.onMouseLeave(container))
    container.addEventListener('mouseup', () => this.onMouseUp(container))
    container.addEventListener('mousemove', (e) => this.onMouseMove(e, container))

    container.addEventListener('touchstart', (e) => this.onTouchStart(e, container))
    container.addEventListener('touchmove', (e) => this.onTouchMove(e, container))
    container.addEventListener('touchend', () => this.onTouchEnd(container))
  }

  // イベントリスナーの削除
  removeEventListeners(container) {
    container.removeEventListener('mousedown', this.onMouseDown)
    container.removeEventListener('mouseleave', this.onMouseLeave)
    container.removeEventListener('mouseup', this.onMouseUp)
    container.removeEventListener('mousemove', this.onMouseMove)

    container.removeEventListener('touchstart', this.onTouchStart)
    container.removeEventListener('touchmove', this.onTouchMove)
    container.removeEventListener('touchend', this.onTouchEnd)
  }

  onMouseDown(e, container) {
    this.isDown = true
    container.style.cursor = 'grabbing'
    this.startX = e.pageX - container.offsetLeft
    this.scrollLeft = container.scrollLeft
  }

  onMouseLeave(container) {
    this.isDown = false
    container.style.cursor = 'grab'
  }

  onMouseUp(container) {
    this.isDown = false
    container.style.cursor = 'grab'
  }

  onMouseMove(e, container) {
    if (!this.isDown) return
    e.preventDefault()
    const x = e.pageX - container.offsetLeft
    const walk = (x - this.startX) * 3
    container.scrollLeft = this.scrollLeft - walk
  }

  onTouchStart(e, container) {
    this.isDown = true
    this.startX = e.touches[0].pageX - container.offsetLeft
    this.scrollLeft = container.scrollLeft
  }

  onTouchMove(e, container) {
    if (!this.isDown) return
    const x = e.touches[0].pageX - container.offsetLeft
    const walk = (x - this.startX) * 3
    container.scrollLeft = this.scrollLeft - walk
  }

  onTouchEnd(container) {
    this.isDown = false
  }
}
