import Player from '@vimeo/player'

export class Modal {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const overlay = document.querySelector('.js-modal_overlay')
      const modalTrg = document.querySelectorAll('.js-modal_trg')
      const modalList = document.querySelectorAll('.js-modal')
      const movieList = document.querySelectorAll('.js-modal_movie')
      const close = document.querySelectorAll('.js-modal_close')
      const body = document.body
      if (!overlay) return
      modalTrg.forEach((elm) => {
        elm.addEventListener('click', (e) => {
          const target = e.currentTarget
          const id = target.dataset.modal
          overlay.classList.add('is-act')
          body.classList.add('is-act')
          modalList.forEach((modal) => {
            const targetId = modal.getAttribute('id')
            if (targetId === id) {
              modal.classList.add('is-act')
            }
          })
        })
      })

      const hide = () => {
        overlay.classList.remove('is-act')
        body.classList.remove('is-act')
        modalList.forEach((data) => {
          data.classList.remove('is-act')
          if (data.querySelector('iframe')) {
            const player = new Player(data.querySelector('iframe'))
            player.pause()
          }
        })
      }

      overlay.addEventListener('click', (e) => {
        hide()
      })
      close.forEach((data) => {
        data.addEventListener('click', (e) => {
          hide()
        })
      })
    })
  }
}
