'use strict'

// import { CookiePolicy } from './lib/CookiePolicy'
import { Svg } from './lib/Svg'
import { TelGrant } from './lib/TelGrant'
import { Smooth } from './lib/Smooth'
import { Drawer } from './lib/Drawer'
import { ScrollStatus } from './lib/ScrollStatus'
import { ScrollAnimation } from './lib/ScrollAnimation'
import { IndexProgramSlider } from './lib/IndexProgramSlider'
import { RecapArticleSlider } from './lib/RecapArticleSlider'
import { kvOpening } from './lib/kvOpening'
import { Accordion } from './lib/Accordion'
// import { Validation } from './lib/Validation'
import { BrowserCheck } from './lib/BrowserCheck'
import { Tab } from './lib/Tab'
import { SessionCheck } from './lib/SessionCheck'
import { MegaMenu } from './lib/MegaMenu'
import { HiddenFixButton } from './lib/HiddenFixButton'
import { ScrollHint } from './lib/ScrollHint'
import { CopyClipboard } from './lib/CopyClipboard'
import { OtherSessionSlider } from './lib/OtherSessionSlider'
import { Modal } from './lib/Modal'
import { BrandsSlider } from './lib/BrandsSlider'
// new CookiePolicy('optout')

new Svg()
new TelGrant()
new Smooth()
new Drawer()
new ScrollStatus()
new ScrollAnimation()
new IndexProgramSlider()
new RecapArticleSlider()
new kvOpening()
new Accordion()
// new Validation()
new BrowserCheck()
new Tab()
new SessionCheck()
new MegaMenu()
new HiddenFixButton()
new ScrollHint()
new CopyClipboard()
new OtherSessionSlider()
new Modal()
new BrandsSlider()
