export class Accordion {
  constructor() {
    const acoTrgs = document.querySelectorAll('.js-aco_trg')

    const animation = (target, content) => {
      const contentHeight = content.scrollHeight
      target.classList.toggle('is-act')
      if (target.classList.contains('is-act')) {
        content.classList.add('is-show')
        // content.style.height = contentHeight + 20 + 'px'
      } else {
        content.style.height = null
        content.classList.remove('is-show')
      }
    }

    acoTrgs.forEach(function (target) {
      target.addEventListener('click', (event) => {
        const target = event.currentTarget
        const content = target.nextElementSibling
        animation(target, content)
      })
    })
  }
}
